// import CryptoJS from 'crypto-js';
// import qs from 'qs';
import Cookies from 'js-cookie'
import axios from 'axios';
import router from '@/router'

export default {
    getHeaders(){
        var AccessToken = Cookies.get('Authorization');

        return {
            "Authorization": AccessToken
        }
    },
    api(url, params, callback, err_callback=null) {
        var baseUrl = '/api';
        var instance = axios.create({
            baseURL: baseUrl,
            timeout: 420000,
            headers: this.getHeaders(),
            withCredentials: true
        });

        if (!params) {
            params = {};
        }

        instance.post(url, params).then(function (response) {
            var status = response.status;
            var data = response.data;

            if (status != 200) {
                console.log('请求失败，状态码：' + status);
            }

            if (data.code == 12000) {
                router.push('/login');
            }

            if (callback) {
                callback(data);
            }
        })
        .catch(function (error) {
            if (err_callback != null) {
                err_callback(error)
            }
            console.log(error);
        });
    },
    loginStatus() {
        var token = Cookies.get('Authorization');
        
        if (token == null) {
            return false;
        }

        return true;
    },
    userinfo() {
        return new Promise((resolve, reject) => {
            this.api("/admin/current", {}, (res) => {
                if (res.code == 10000) {
                    resolve(res.data);
                }
            });
        });
    },
    inArray(search,array){
        for(var i in array){
            if(array[i]==search){
                return true;
            }
        }
        return false;
    },
    isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
    },
    async getAuthorizer(user){
        const casbinjs = require('casbin.js');

        // Set your backend casbin service url
        const authorizer = new casbinjs.Authorizer('auto', {endpoint: '/api/casbin'});

        // When the identity shifts, reset the user. Casbin.js will automatically fetch the permission from the endpoint.
        await authorizer.setUser(user);
        return authorizer;
    }
}
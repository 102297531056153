<template>
    <div class="content">
        <el-row class="header">
            <el-col :span="12">
                <div class="left">
                    <div class="logo"></div>
                    <ul class="navs">
                        <li :class="$route.path != '/admin/list' && $route.path != '/admin/exam' && $route.path != '/rules' ? 'nav_hover' : ''">
                            <a href="javascript:void(0)" @click="toGame">赛事</a>
                        </li>
                        <li :class="$route.path == '/admin/list' || $route.path == '/admin/exam' ? 'nav_hover' : ''">
                            <a href="javascript:void(0)" @click="toAdmin">管理员列表</a>
                        </li>
                        <!-- <li :class="$route.path == '/rules' || $route.path == '/rules' ? 'nav_hover' : ''">
                            <a href="javascript:void(0)" @click="toRules">规则</a>
                        </li> -->
                    </ul>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="right">
                    <el-dropdown class="game_create" trigger="click" @command="toCreate">
                        <span>
                            创建赛事<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="championships">
                                <!-- <div class="game_type game_line"> -->
                                <div class="game_type">
                                    <img src="~@/assets/images/icon-championships.png" />
                                    <div>
                                        <p>创建一个锦标赛</p>
                                        <p>锦标赛可以分阶段进行比赛</p>
                                    </div>
                                </div>
                            </el-dropdown-item>
                            <el-dropdown-item command="ordinary">
                                <div class="game_type">
                                    <img src="~@/assets/images/icon-ordinary.png" />
                                    <div>
                                        <p>创建一个普通赛</p>
                                        <!-- <p>可创建瑞士轮，单败淘汰制，双败淘汰制</p> -->
                                        <p>可以创建一个瑞士轮的单段赛</p>
                                    </div>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <span v-if="user != null" class="name">{{user.type == 0 ? '超管' : '管理员'}}：{{user.userid}}</span>
                    <el-button v-if="user != null" type="primary" size="mini" class="logout" @click="logout">退出</el-button>
                    <!-- <el-button v-if="user == null" size="mini" class="logout" @click="toReg">注册</el-button>
                    <el-button v-if="user == null" type="primary" size="mini" class="logout" @click="toLogin">登录</el-button> -->
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: "BaseHeader",
    data() {
        return {
            user: null
        };
    },
    async created() {
        this.user = await this.$util.userinfo();
    },
    methods: {
        toRules(){
            this.$router.push('/rules');
        },
        toReg(){
            this.$router.push({
                path: '/login',
                query: {
                    type: 'reg'
                }
            });
        },
        toLogin(){
            this.$router.push('/login');
        },
        toGame(){
            this.$router.push('/game/list');
        },
        toAdmin(){
            this.$router.push('/admin/list');
        },
        toCreate(command){
            this.$router.push('/game/create/' + command);
        },
        logout(){
            Cookies.remove('Authorization');
            Cookies.remove('expireTime');
            // Cookies.remove('UserInfo');
            
            // this.$util.api('/logout');
            this.$router.push('/login');
        }
    },
};
</script>

<style scoped>
/deep/ .el-button{
    border: 1px solid #ffffff99;
}
.content{
    background-color: #22252C;
}

/* @media screen and (max-width: 960px){

} */

/* @media screen and (min-width: 1190px){ */
    .header{
        width: 1400px;
        margin: 0 auto;
        height: 64px;
    }
    .right{
        text-align: right;
    }
    .logo{
        width: 167px;
        height: 64px;
        background: #14151A;
        display: inline-block;
        vertical-align: middle;
        background: url('@/assets/images/logo.png') no-repeat center center;
        background-size: 100% 100%;
    }
    .navs{
        height:40px;
        display: inline-block;
        margin-top: 12px;
    }
    .navs li{
        display: inline-block;
        line-height: 40px;
        margin-left: 64px;
    }
    .navs li a{
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        display: block;
    }
    .navs li:hover, .nav_hover{
        border-bottom: 3px solid #FF6A00;
    }
    .navs li a:hover{
        color: #FF6A00;
    }
    .game_create{
        cursor: pointer;
        margin-top: 22px;
        font-size: 16px;
    }
    .game_type{
        padding: 10px;
    }
    .game_line{
        border-bottom: 1px solid #585858;
    }
    .game_type img{
        width: 30px;
        height: 30px;
        margin: 10px;
        vertical-align: -15%;
    }
    .game_type div{
        display: inline-block;
        height: 50px;
        margin-right: 10px;
    }
    .game_type div p{
        line-height: 25px;
    }
    .name{
        margin-left: 50px;
    }
    .logout{
        margin-left: 30px;
    }
/* } */
</style>
import axios from 'axios'

const setcomp = {
    state: {
        userInfo:null,
        router:null
    },
    getters: {},
    mutations: {
        getuserInfo(state,data){
            state.userInfo=data
        },
        getrouter(state,data){
            state.router=data
        }
    },
    actions: {

    },
}
export default {
    namespaced: true,
    ...setcomp,
}
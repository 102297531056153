import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/utils/utils'
import Layout from "@/views/Layout"

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

Vue.use(VueRouter)
let routes = [
    {
        path: "/",
        component: Layout,
        redirect: '/game/list',
        children: [
            {
                path: '/view/:id',
                name: 'GameView',
                component: () => import('@/views/GameView'),
                meta: {
                    title: '赛事信息'
                },
            },
            {
                path: '/game/list',
                name: 'GameList',
                component: () => import('@/views/GameList'),
                meta: {
                    title: '所有赛事',
                    requireAuth: true
                },
            },
            {
                path: '/game/detail/:id',
                name: 'GameDetail',
                component: () => import('@/views/GameDetail'),
                meta: {
                    title: '赛事信息',
                    // requireAuth: true
                },
            },
            {
                path: '/game/versus/:id',
                name: 'GameInfo',
                component: () => import('@/views/Versus'),
                meta: {
                    title: '对阵表',
                    // requireAuth: true
                },
            },
            {
                path: '/game/create/:type',
                name: 'GameCreate',
                component: () => import('@/views/GameCreate'),
                meta: {
                    title: '创建赛事',
                    requireAuth: true
                },
            },
            {
                path: '/admin/list',
                name: 'AdminList',
                component: () => import('@/views/AdminList'),
                meta: {
                    title: '权限管理',
                    requireAuth: true
                },
            },
            {
                path: '/admin/exam',
                name: 'AdminExam',
                component: () => import('@/views/AdminExam'),
                meta: {
                    title: '注册审核',
                    requireAuth: true
                },
            },
            {
                path: '/rules',
                name: 'GameRules',
                component: () => import('@/views/GameRules'),
                meta: {
                    title: '赛事规则',
                    requireAuth: true
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login'),
        meta: {
            title: '登录'
        }
    },
    // {
    //     path: "/404",
    //     name: "NotFound",
    //     component: () => import("../views/NotFound")
    // },
    // {
    //     path: '*',
    //     redirect: '/'
    // },
    {
        path: '/h5/game/detail/:id',
        name: 'H5GameList',
        component: () => import('@/views/H5GameList'),
        meta: {
            title: '赛事详情'
        }
    },
    {
        path: '/h5/game/versus/:id',
        name: 'H5GameDetail',
        component: () => import('@/views/H5GameDetail'),
        meta: {
            title: '对阵列表'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})

export default router
<template>
    <div class="content">
        <!-- <el-row class="footer">
            <el-col :span="6">
                <img src="~@/assets/images/logo-bottom.png" class="logo" />
            </el-col>
            <el-col :span="6">
                <div class="title">探索</div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="title">探索</div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="title">探索</div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
                <div class="link">
                    <el-link :underline="false" href="#">快捷入口</el-link>
                </div>
            </el-col>
        </el-row> -->
        <div class="bottom">
            <span>
                <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023011185号-1</a>
            </span>
            <span>© 2023 gtcgtool.cn</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseFooter",
    components: {},
    data() {
        return {};
    },
    created() { },
    mounted() { },
    methods: {

    },
};
</script>

<style scoped>
.logo{
    width: 141px;
    height: 54px;
    margin: 50px 0;
}
.content{
    background-color: #1F2128;
}

/* @media screen and (max-width: 960px){

} */

/* @media screen and (min-width: 1190px){ */
    .footer{
        width: 1400px;
        height: 157px;
        overflow: hidden;
        margin: 0 auto;
    }
    .bottom{
        margin: 0 auto;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-top: 1px solid rgba(255,255,255,0.28);
        width: 1400px;
        opacity: 0.3;
        font-weight: 400;
        font-size: 11px;
        color: #FFFFFF;
    }
    .bottom a{
        color: #fff;
    }
    .bottom a:hover{
        color: #FF6A00;
    }
    .bottom span{
        margin: 0 20px;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        margin-top: 26px;
        margin-bottom: 8px;
    }
    .link{
        margin-bottom: 8px;
    }
    .link a{
        height: 16px;
        opacity: 0.3;
        line-height: 16px;
        font-weight: 400;
        font-size: 11px;
        color: #FFFFFF;
    }
    .link a:hover{
        color: #FFFFFF !important;
        opacity: 1;
    }
/* } */
</style>
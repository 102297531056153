<template>
    <div>
        <base-header></base-header>
        <router-view></router-view>
        <base-footer></base-footer>
    </div>
</template>
<script>
import BaseHeader from '@/components/BaseHeader.vue'
import BaseFooter from '@/components/BaseFooter.vue'

export default {
    name: 'Layout',
    components: {
        BaseHeader,
        BaseFooter
    }
};
</script>


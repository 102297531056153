<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: "App",
};
</script>
<style>
@font-face {
    font-family: alibaba;
    src: url("@/assets/font/AlibabaPuHuiTi-2-55-Regular.ttf") ;
}
@font-face {
    font-family: alibaba;
    src: url("@/assets/font/AlibabaPuHuiTi-2-85-Bold.ttf");
    font-weight: bold;
}

* {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
a,img {
    border: 0;
    outline: none;
    text-decoration: none;
}
html,body {
    background-color: #EFF3F6;
    font: 14px/160% "alibaba" !important;
    color: #fff;
    z-index: 1;
}
h1,h2,h3,h4,h5,h6,button,input,select,textarea {
    font-size: 100%
}
body{
    background-color: #282A33;
}

@media screen and (min-width: 1190px){
    body{
        min-width: 1400px;
    }
}

.clear {
    clear: both;
}
</style>

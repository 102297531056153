import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from "./utils/utils"
import ElementUI from 'element-ui';
import './assets/style/theme/index.css';
import './assets/style/theme/common.css';
import VueClipBoard from 'vue-clipboard2'
import SlideVerify from 'vue-monoplasty-slide-verify';
import VueTouch from 'vue-touch'

Vue.config.productionTip = false
Vue.prototype.$util = util
Vue.use(ElementUI);
Vue.use(VueClipBoard);
Vue.use(SlideVerify);
Vue.use(VueTouch, { name: 'v-touch' })

router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    
    if (to.meta.requireAuth && !util.loginStatus()) {
        next({
            path: '/login'
        })
    }

    next()
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')